<script>
export default {
  name: 'HelpCenterBtn',
  computed: {
    language () {
      return this.$store.getters.getLanguage
    }
  }
}
</script>
<template>
  <a class="helpcenter-btn--container" :href="`https://plooral.zendesk.com/hc/${language === 'pt-BR' ? 'pt-br' : 'en-us'}`" target="_blank">
    <v-icon color="#fff">mdi-help-circle-outline</v-icon>
    <span>{{ $t('global:help') }}</span>
  </a>
</template>
<style lang="scss">
.helpcenter-btn--container {
  display: inline-block;
  position: fixed;
  bottom: 15px;
  right: 20px;
  padding: 10px 18px;
  border-radius: 100px;
  background-color: $primary-medium;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  z-index: 1000;
  > * {
    display: inline-block;
  }
  span {
    font-family: system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Arial,sans-serif;
    font-weight: bold;
    margin-left: 8px;
    color: #fff
  }
}
</style>
